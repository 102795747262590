import $ from 'jquery'

$(function () {
  // Switch to active tab
  var hash = document.location.hash
  if (hash) {
    $(`a[href="${hash.replace('#tab-', '#')}"]`).tab('show')
  }

  // Save active tab in URL hash
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
    window.location.hash = e.target.hash.replace('#', '#tab-')
  })
})

import { timeHours } from "d3-time"
import { trim } from 'lodash'

function pad(num, size) {
  var s = num + ""
  while (s.length < size) s = "0" + s
  return s
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export default class Utils {
  static units = 'imperial'

  static resample(data, period, combine=Utils.average) {
    let samples = {}

    let timestamps = []
    let values = []

    for (let i = 0; i < data.timestamps.length; ++i) {
      const value = data.values[i]

      if (isNumeric(value)) {
        const sample = Math.floor(data.timestamps[i] / period) * period

        samples[sample] = samples[sample] || []
        samples[sample].push(value)
      }
    }

    for (let timestamp in samples) {
      timestamps.push(parseFloat(timestamp))
      values.push(combine(samples[timestamp]))
    }

    return {
      timestamps: timestamps,
      values: values
    }
  }

  static sum(values) {
    return values.reduce(function (a, b) { return a + b }, 0)
  }

  static average(values) {
    return Utils.sum(values) / values.length
  }

  static min(values) {
    return Math.min.apply(Math, values)
  }

  static max(values) {
    return Math.max.apply(Math, values)
  }

  static weightedAverage(values) {
    let middle = Math.floor(values.length / 2)

    let sum = values[middle]
    let totalWeight = 1
    let currentWeight = 0.5

    for (let i = 1; i < values.length / 2; ++i) {
      if (values[middle + i]) {
        sum += values[middle + i] * currentWeight
        totalWeight += currentWeight
      }

      if (values[middle - i]) {
        sum += values[middle - i] * currentWeight
        totalWeight += currentWeight
      }

      currentWeight /= 2
    }

    return sum / totalWeight
  }

  static standardDeviation(values) {
    if (values.length === 0) {
      return 0
    }

    const average = Utils.average(values)
    const variance = values.map((x) => {
      const v = x - average
      return v * v
    }).reduce((a, b) => a + b) / values.length

    return Math.sqrt(variance)
  }

  static percentile(values, percentile) {
    return values[Math.floor(values.length * percentile)]
  }

  static round(values, precision) {
    return values.map((x) => +x.toFixed(precision))
  }

  static merge(a, b, calculate) {
    let timestamps = []
    let values = []

    let aIndex = 0
    let bIndex = 0

    while (aIndex < a.timestamps.length && bIndex < b.timestamps.length) {
      // Increase indexes until we find matching timestamps
      while (a.timestamps[aIndex] < b.timestamps[bIndex]) {
        const timestamp = a.timestamps[aIndex]
        const value = calculate(a.values[aIndex], null)

        timestamps.push(timestamp)
        values.push(value)

        ++aIndex
      }

      while (b.timestamps[bIndex] < a.timestamps[aIndex]) {
        const timestamp = b.timestamps[bIndex]
        const value = calculate(null, b.values[bIndex])

        timestamps.push(timestamp)
        values.push(value)

        ++bIndex
      }

      // Check if reached end of either array
      if (aIndex >= a.timestamps.length || bIndex >= b.timestamps.length)
        break

      // Calculate merged value at timestamp
      const timestamp = a.timestamps[aIndex]
      const value = calculate(a.values[aIndex], b.values[bIndex])

      timestamps.push(timestamp)
      values.push(value)

      // Increase indexes in step
      ++aIndex
      ++bIndex
    }

    // Add any timestamp/value pairs from end of longer array
    while (aIndex < a.timestamps.length) {
      const timestamp = a.timestamps[aIndex]
      const value = calculate(a.values[aIndex], null)

      timestamps.push(timestamp)
      values.push(value)

      ++aIndex
    }

    while (bIndex < b.timestamps.length) {
      const timestamp = b.timestamps[bIndex]
      const value = calculate(null, b.values[bIndex])

      timestamps.push(timestamp)
      values.push(value)

      ++bIndex
    }

    return {
      timestamps: timestamps,
      values: values
    }
  }

  static formatTime(ms) {
    var secs = Math.floor(ms / 1000)
    var msleft = ms % 1000
    var hours = Math.floor(secs / (60 * 60))
    var divisor_for_minutes = secs % (60 * 60)
    var minutes = Math.floor(divisor_for_minutes / 60)
    var divisor_for_seconds = divisor_for_minutes % 60
    var seconds = Math.ceil(divisor_for_seconds)

    if (hours > 0) {
      return hours + ":" + pad(minutes, 2) + ":" + pad(seconds, 2)
    } else {
      return minutes + ":" + pad(seconds, 2)
    }
  }

  static isPaceUnits(units) {
    return units == 'min/km' || units == 'min/mi'
  }

  static formatter(format) {
    if (format == 'pace') {
      return this.formatPace
    } else if (format == 'integer') {
      return this.formatInteger
    } else {
      return this.formatFloat
    }
  }

  static formatFloat(value, precision) {
    precision = typeof(precision) == "number" ? precision : 1

    if (isFinite(value)) {
      return value.toFixed(precision)
    } else {
      return 'N/A'
    }
  }

  static formatInteger(value) {
    if (value) {
      return Math.round(value)
    } else {
      return 'N/A'
    }
  }

  static formatPace(value) {
    if (isNaN(value)) {
      return 'N/A'
    }

    var seconds = value * 60

    var multiplier = 1
    if (seconds < 0) {
      seconds = -seconds
      multiplier = -1
    }

    var mins = Math.floor(seconds / 60) * multiplier
    var secs = Math.floor(seconds % 60)

    return mins + ":" + pad(secs, 2)
  }

  static formatDistance(meters, precision = 1, units = this.units) {
    var scale = (units == "metric") ? 1000 : 1609.34

    if (isFinite(meters)) {
      return (meters / scale).toFixed(precision)
    } else {
      return 'N/A'
    }
  }

  static formatSmallDistance(meters, units = this.units) {
    var scale = (units == "metric") ? 1 : 3.2808
    return Math.round(meters * scale)
  }

  static formatDistanceRange(fromMeters, toMeters, units = this.units) {
    if (this.smallDistance(toMeters, units)) {
      return `${this.formatSmallDistance(fromMeters)} - ${this.formatSmallDistance(toMeters)}${this.smallDistanceUnits(units)}`
    } else {
      return `${this.formatDistance(fromMeters, 1, units)} - ${this.formatDistance(toMeters, 1, units)}${this.distanceUnits(units)}`
    }
  }

  static distanceUnits(units = this.units) {
    if (units == "metric") {
      return "km"
    } else {
      return "mi"
    }
  }

  static smallDistance(meters, units = this.units) {
    return meters != 0 && meters < 1000
  }

  static smallDistanceUnits(units = this.units) {
    if (units == "metric") {
      return "m"
    } else {
      return "ft"
    }
  }

  static timeFormatter() {
    return Utils.formatTime(this.value)
  }

  static paceFormatter() {
    return Utils.formatPace(this.value)
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  static versionCompare(a, b) {
    a = a.toString().split('.').map(Number)
    b = b.toString().split('.').map(Number)

    for (var i = 0; i < Math.max(a.length, b.length); ++i) {
      a[i] = typeof a[i] === 'undefined' ? 0 : Number(a[i])
      b[i] = typeof b[i] === 'undefined' ? 0 : Number(b[i])

      if (a[i] > b[i]) {
        return 1
      } else if (a[i] < b[i]) {
        return -1
      }
    }

    return 0
  }

  static versionGte(version, required) {
    return Utils.versionCompare(version, required) >= 0
  }

  static versionLte(version, required) {
    return Utils.versionCompare(version, required) <= 0
  }

  static versionBetween(version, min, max) {
    return Utils.versionGte(version, min) && Utils.versionLte(version, max)
  }

  static simpleFormat(str) {
    str = str.replace(/\r\n?/, "\n")
    str = trim(str)
    if (str.length > 0) {
      str = str.replace(/\n\n+/g, '</p><p>')
      str = str.replace(/\n/g, '<br />')
      str = '<p>' + str + '</p>'
    }
    return str
  }

  static conversion(from, to) {
    if (from == "m/s") {
      if (to == "min/km") {
        return this.metersPerSecondToMinutesPerKilometerConversion
      } else if (to == "min/mi") {
        return this.metersPerSecondToMinutesPerMileConversion
      }
    } else if (from == "m") {
      if (to == "ft") {
        return this.metersToFeetConversion
      }
    }

    return this.nullConversion
  }

  static metersPerSecondToMinutesPerKilometerConversion(value) { if (value !== 0) return 16.6666667 / value }
  static metersPerSecondToMinutesPerMileConversion(value) { if (value !== 0) return 26.8224 / value }
  static metersToFeetConversion(value) { return value * 3.280839895 }

  static nullConversion(value) { return value; }

  static getPixelRatio() {
    return PIXEL_RATIO
  }
}

const PIXEL_RATIO = (function () {
  const ctx = document.createElement('canvas').getContext('2d')
  const dpr = window.devicePixelRatio || 1
  const bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1

  return dpr / bsr
})()

import $ from 'jquery'

import { CountUp } from 'countup.js'

class Counter {
  constructor (el) {
    this.el = $(el)

    this.from = this.el.data('from') || 0
    this.to = this.el.data('to')
    this.duration = this.el.data('duration') || 1
    this.suffix = this.el.data('suffix') || ''
    this.decimals = this.el.data('decimals') || 0
  }

  start () {
    new CountUp(this.el[0], this.to, {
      startVal: this.from,
      duration: this.duration,
      decimalPlaces: this.decimals,
      suffix: this.suffix
    }).start()
  }

  static countAll (scope) {
    $(scope).each(function () {
      let counter = new Counter(this)
      counter.start()
    })
  }
}

$(function () {
  Counter.countAll('[data-count]')
})

export default Counter

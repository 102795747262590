/* global alert, stripe */

import $ from 'jquery'

import { DirectUpload } from 'activestorage'

export default class Profile {
  constructor () {
    this.setupSaveButton()

    this.initDatePicker()
    this.initUnits()
    this.initInjuries()
    this.initShoeSize()
    this.initSubscribeButton()

    this.initCustomBranding()
  }

  setupSaveButton () {
    const button = $('[data-behavior="save-active-form"]')

    button.on('click', function () {
      $('.tab-pane.active form[data-header-button]').trigger('submit')
    })

    $('.page-header--tabs a').on('shown.bs.tab', function (e) {
      const pane = $($(this).attr('href'))
      const hasForm = pane.find('form[data-header-button]').length

      button
        .toggleClass('disabled', !hasForm)
        .prop('disabled', !hasForm)
    })
  }

  initDatePicker () {
    // Birthdate selection
    $('.date-picker').datepicker({
      yearRange: '-100:+0',
      changeYear: true,
      dateFormat: 'yy/mm/dd'
    })
  }

  initUnits () {
    // Toggle metric/imperial form fields when changed
    $('input[name="profile[units]"]').on('click', function () {
      if ($(this).val() === 'metric') {
        $('[data-system="imperial"]').hide().find('input').attr('disabled', 'disabled')
        $('[data-system="metric"]').show().find('input').removeAttr('disabled')
      } else {
        $('[data-system="metric"]').hide().find('input').attr('disabled', 'disabled')
        $('[data-system="imperial"]').show().find('input').removeAttr('disabled')
      }
    })
  }

  initInjuries () {
    $('#injury-select').select2({
      theme: 'bootstrap',
      placeholder: 'Injury Location',
      allowClear: true
    })

    $('.delete-injury').on('click', function () {
      $(this).closest('.injury').remove()
    })
  }

  initShoeSize () {
    function formatShoeSize (size) {
      if (!size.id) {
        return size.text
      }

      const result = $('<div class="select-shoe-size"></div>')
      result.append($('<span>').text(size.text))
      result.append($('<small>').text(size.element.dataset.alt))

      return result
    }

    $('#profile_default_shoe_size').select2({
      theme: 'bootstrap',
      placeholder: 'Select Size',
      allowClear: true,
      templateResult: formatShoeSize,
      templateSelection: formatShoeSize,
      minimumResultsForSearch: Infinity
    })
  }

  initSubscribeButton () {
    $('button[data-subscribe]').on('click', (e) => {
      this.createCheckoutSession().then((data) => {
        stripe.redirectToCheckout({
          sessionId: data.sessionId
        }).then((result) => {
          if (result.error) {
            console.log(result.error.message)
          }
        })
      })
    })
  }

  createCheckoutSession () {
    return $.post('/subscribe/checkout', {}, null, 'json')
  }

  initCustomBranding () {
    $('.custom-brand-selector').each(function () {
      const container = $(this)

      const input = $(this).find('input:file')

      input.on('change', function () {
        const file = input.get(0).files[0]

        // Update preview
        const src = URL.createObjectURL(file)
        container.addClass('custom')
        container.find('[data-custom-brand-image]').attr('src', src)

        // Upload image
        const url = input.data('direct-upload-url')
        const upload = new DirectUpload(file, url)
        upload.create((error, blob) => {
          if (error) {
            alert(error)
          } else {
            $('<input type="hidden" />')
              .attr('name', input.attr('name'))
              .val(blob.signed_id)
              .appendTo(container)
          }
        })
      })

      const reset = $(this).find('.btn-reset')

      reset.on('click', function () {
        container.removeClass('custom')

        $('<input type="hidden" />')
          .attr('name', input.attr('name'))
          .val('delete')
          .appendTo(container)
      })
    })
  }
}

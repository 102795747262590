import $ from 'jquery'

import Print from './print'

export default class ShoePrint extends Print {
  constructor (el, options = {}) {
    super(el, options)

    this.sizer = this.$el.append(this.buildSizer(600, 720))

    this.cache = {}

    this.left = this.buildFoot({
      ...options,
      foot: 'left',
      enabled: options.left || (this.$el.data('left') !== undefined),
      onUpdate: () => this.updateMirror()
    })

    this.right = this.buildFoot({
      ...options,
      foot: 'right',
      enabled: options.right || (this.$el.data('right') !== undefined),
      onUpdate: () => this.updateMirror()
    })
  }

  buildFoot (options) {
    // Override in subclass
  }
}

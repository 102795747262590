import Highstock from './highstock'

Highstock.seriesType(
  'trend',
  'sma',
  {
    name: 'Trend Indicator',
    params: {}
  },
  {
    getValues: function (series, params) {
      const period = params.period

      const data = series.yData.map((y, index) => {
        if (isFinite(y)) {
          const x = series.xData[index]
          return { x: x, y: y }
        }
      }).filter(n => n)

      let xData = []
      let yData = []
      let values = []

      let sum = 0

      for (let i = 0; i < data.length; ++i) {
        const point = data[i]
        const range = Math.min(i + 1, period)

        sum += point.y

        if (i >= period) {
          sum -= data[i - period].y
        }

        const value = sum / range

        values.push(value)
        xData.push(point.x)
        yData.push(point.y)
      }

      return {
        xData: xData,
        yData: yData,
        values: values
      }
    }
  }
)

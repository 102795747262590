import { yAxisOptions } from './options'

export default class BasicSeries {
  constructor (options) {
    this.visible = false

    this.onShow = options.onShow || function () {}
    this.onHide = options.onHide || function () {}
    this.onUpdate = options.onUpdate || function () {}
  }

  toggle () {
    if (this.visible) {
      this.hide()
    } else {
      this.show()
    }
  }

  show () {
    if (!this.visible && this.series) {
      this.visible = true

      this.series.show()

      if (this.averageSeries) {
        this.averageSeries.show()
        this.updateAverageSeriesLine()
      }

      if (this.pointSeries) {
        this.pointSeries.show()
      }

      this.onShow(this)

      this.chart.redraw()
      this.chart.resetAxis()
    }
  }

  hide () {
    if (this.visible && this.series) {
      this.visible = false

      this.series.hide()

      if (this.averageSeries) {
        this.averageSeries.hide()
      }

      if (this.pointSeries) {
        this.pointSeries.hide()
      }

      this.onHide(this)
    }
  }

  showAxis () {
    this.yAxis.update({ visible: true })
  }

  hideAxis () {
    this.yAxis.update({ visible: false })
  }

  get yAxis () {
    return this.chart.highcharts.yAxis[this.yAxisIndex]
  }

  get yAxisIndex () {
    return Object.keys(yAxisOptions).indexOf(this.axis)
  }

  afterSetExtremes () {
    if (this.visible && this.averageSeries) {
      this.updateAverageSeriesLine()
    }
  }
}

import jQuery from 'jquery'

import Rails from '@rails/ujs'
Rails.start()

import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui-timepicker-addon'

import 'bootstrap-sass'

import 'jquery-sparkline'
import 'bootstrap-tagsinput'

import RunScribe from 'runscribe'
window.RunScribe = RunScribe

import $ from 'jquery'

function init () {
  $('[data-toggle="tooltip"]').tooltip({ container: 'body' })
  $('.info-tooltip').not('.clickable').on('click', false)
}

$(init)

export default { init }

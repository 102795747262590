import $ from 'jquery'
import BigPicture from 'bigpicture'

$(document).on('click', '.lesson[rel="lightbox"]', function (e) {
  e.preventDefault()

  const vimeoId = $(this).attr('href').replace('https://vimeo.com/', '')

  BigPicture({
    el: e.target,
    vimeoSrc: vimeoId
  })
})

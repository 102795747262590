import $ from 'jquery'

import CalibrationChart from '../charts/calibration_chart'
import Utils from '../utils'

export default class CalibrationView {
  constructor () {
    this.loaded = false
    this.customDistance = false

    this.container = $('#calibration-chart-container')

    this.startField = $('#start-timestamp')
    this.finishField = $('#finish-timestamp')
    this.durationField = $('#run-duration')
    this.distanceField = $('#run-distance')

    this.distanceField.on('input', () => {
      if (this.distanceField.val() === '') {
        this.customDistance = false

        var start = parseInt(this.startField.val()) || 0
        var finish = parseInt(this.finishField.val()) || 0

        this.updateDistance(start, finish)
      } else {
        this.customDistance = true
      }
    })

    this.chart = RunScribe.chart = new CalibrationChart(this.container, {
      onChange: (start, finish) => {
        // Don't update on first load
        if (!this.loaded) {
          this.loaded = true
          return
        }

        // Hidden form fields
        this.startField.val(start)
        this.finishField.val(finish)

        // Run duration display
        this.durationField.text(Utils.formatTime(finish - start))

        // Run distance form input
        if (!this.customDistance) {
          this.updateDistance(start, finish)
        }
      },

      onLoad: (metric) => {
        this.metricData = metric.data

        if (this.finishField.val() !== '') {
          var start = parseInt(this.startField.val()) || 0
          var finish = parseInt(this.finishField.val()) || 0
        } else {
          var data = metric.data.combined

          var index = 0
          while (!data.values[index] || !data.values[index + 1]) ++index
          start = data.timestamps[index]

          index = data.values.length - 1
          while (!data.values[index] || !data.values[index - 1]) --index
          finish = data.timestamps[index]
        }

        this.chart.setRange(start, finish)
      }
    })
  }

  updateDistance (start, finish) {
    var distances = this.metricData.distances
    var index = 0

    while (distances.timestamps[index] < start) ++index
    var startDistance = distances.values[index] || 0

    index = distances.values.length - 1
    while (distances.timestamps[index] > finish) --index
    var finishDistance = distances.values[index]

    this.distanceField.val(Utils.formatDistance(finishDistance - startDistance, 2))
  }
}

import $ from 'jquery'

import { debounce } from 'debounce'

const DEFAULT_RATIO = 720 / 600

export default class Autosize {
  constructor (el, ratio) {
    this.el = $(el)
    this.ratio = ratio || DEFAULT_RATIO
  }

  setup () {
    this.setupEvents()
    this.resize()
  }

  resize () {
    this.el.height('auto')

    var maxWidth = this.el.parent().width()
    var maxHeight = this.el.parent().height()

    var width = maxWidth
    var height = width * this.ratio

    if (height > maxHeight) {
      height = maxHeight
      width = height / this.ratio
    }

    this.el.width(width).height(height)
  }

  setupEvents () {
    $(window).on('resize', debounce(() => this.resize(), 50))
    $('a').on('shown.bs.tab', () => this.resize())
  }
}

$(function () {
  $('[data-behavior="autosize-print"]').each(function () {
    const ratio = $(this).data('ratio')
    new Autosize(this, ratio).setup()
  })
})

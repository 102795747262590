import $ from 'jquery'

$(document).on('click', '[data-toggle="edit"]', function (e) {
  e.preventDefault()

  const editable = $(this).closest('[data-behavior="editable"]')

  if (editable.hasClass('editing')) {
    editable.css('width', '')
    editable.removeClass('editing')
  } else {
    const width = editable.width()
    editable.css('width', width)
    editable.addClass('editing')
    editable.find('.edit input:visible:first').focus().select()
  }
})

$(document).on('submit', '[data-behavior="editable"] form', function () {
  const editable = $(this).closest('[data-behavior="editable"]')
  editable.addClass('loading')
})

$(document).on('ajax:complete', '[data-behavior="editable"] form', function () {
  const editable = $(this).closest('[data-behavior="editable"]')
  editable.removeClass('loading')
})

import $ from 'jquery'

import Chart from './chart'

const DefaultOptions = {
  chart: {
    height: 75
  },
  tooltip: { enabled: false },
  scrollbar: { enabled: true, height: 0 },
  navigator: {
    enabled: true,
    height: 75,
    margin: 0,
    maskFill: 'rgba(0, 0, 0, 0.25)',
    maskInside: false,
    outlineColor: 'rgba(255, 255, 255, 0.25)',
    outlineWidth: 1,
    series: {
      lineColor: '#00ff00',
      dataGrouping: {
        groupPixelWidth: 5
      }
    },
    xAxis: {
      gridLineWidth: 0,
      labels: { enabled: false }
    },
    handles: {
      symbols: ['rightarrow', 'leftarrow'],
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
      lineWidth: 0,
      width: 30,
      height: 75
    }
  },
  xAxis: [{
    min: 0,
    lineWidth: 0,
    tickLength: 0,
    labels: {
      enabled: false
    }
  }],
  yAxis: {
    height: 0,
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  plotOptions: {
    series: {
      enableMouseTracking: false
    }
  }
}

const PlotBandOptions = {
  color: 'rgba(0, 0, 0, 0.5)',
  borderWidth: 1,
  borderColor: 'rgba(255, 255, 255, 0.05)',
  zIndex: 5
}

export default class NavigatorChart extends Chart {
  constructor (id, options) {
    super(id, { options: $.extend(true, {}, DefaultOptions, options) })
  }

  isRange (start, finish) {
    const axis = this.xAxis
    return start === axis.userMin && finish === axis.userMax
  }

  setStrideRateRange (min, max) {
    this.removePlotBandY('outofrange')

    if (min * 2 > this.yAxis.min) {
      this.addPlotBandY('outofrange', this.yAxis.min, min * 2, PlotBandOptions)
    }

    if (max * 2 < this.yAxis.max) {
      this.addPlotBandY('outofrange', max * 2, this.yAxis.max, PlotBandOptions)
    }
  }

  setStrideRateBounds (min, max) {
    this.yAxis.update({ min: min * 2, max: max * 2 })
  }

  get yAxis () {
    return this.highcharts.navigator.yAxis
  }
}

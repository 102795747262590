import Highstock from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
import HSIndicators from 'highcharts/indicators/indicators'
import HSRegressions from 'highcharts/indicators/regressions'

HighchartsMore(Highstock)
HSIndicators(Highstock)
HSRegressions(Highstock)

Highstock.seriesType('boxplot-z', 'boxplot', {
  pointPadding: 0,
  groupPadding: 0
}, {
  pointArrayMap: ['low', 'q1', 'median', 'q3', 'high', 'z'],

  translate: function () {
    Highstock.seriesTypes.boxplot.prototype.translate.call(this)

    Highstock.each(this.points, function (point) {
      var shapeArgs = point && point.shapeArgs

      if (shapeArgs && typeof point.z === 'number') {
        shapeArgs.x += (shapeArgs.width - shapeArgs.width * point.z) / 2
        shapeArgs.width *= point.z
      }
    })
  }
})

export default Highstock

import $ from 'jquery'
import Cookies from 'js-cookie'

import Series from './series'
import { defaults } from './options'

export default class Chart {
  constructor (id, options) {
    var chart = this

    this.id = id
    this.el = $(id)

    this.options = options

    this.gapThreshold = this.el.data('gap-threshold')
    this.smoothingLevel = options.smoothing || Cookies.get('smoothingLevel') || 'medium'

    this.series = []

    var chartOptions = this.options = $.extend(true, {}, defaults, options.options)

    $.each(chartOptions.xAxis, function (i, axis) {
      chartOptions.xAxis[i] = $.extend(true, axis, {
        min: options.min,
        max: options.max,
        events: {
          afterSetExtremes: $.proxy(chart.afterSetExtremes, chart)
        }
      })
    })

    this.onZoom = options.onZoom

    this.el.highcharts('StockChart', chartOptions)
    this.highcharts = this.el.highcharts()
  }

  afterSetExtremes (event) {
    if (this.onZoom) {
      this.onZoom(event)
    }

    this.eachSeries(function (series) {
      series.afterSetExtremes(event)
    })
  }

  eachSeries (callback) {
    $.each(this.series, function (i, series) { callback(series) })
  }

  visibleSeries () {
    return $(this.highcharts.series).filter(function () { return this.visible })
  }

  addSeries (series) {
    this.series.push(series)
  }

  newSeries (options) {
    var series = new Series(this, options)
    this.addSeries(series)
    return series
  }

  setSmoothing (level) {
    this.eachSeries(function (series) {
      series.setSmoothing(level)
    })

    this.smoothingLevel = level
    Cookies.set('smoothingLevel', level)
  }

  showLoading (str) {
    return this.highcharts.showLoading(str)
  }

  hideLoading () {
    return this.highcharts.hideLoading()
  }

  redraw () {
    return this.highcharts.redraw()
  }

  reflow () {
    return this.highcharts.reflow()
  }

  // Triggers a reset on the x-axis to ensure that shared tooltips are kept in sync.
  // This appears to be required due to a bug in Highcharts itself.
  resetAxis () {
    const axis = this.xAxis
    axis.isDirty = true
    axis.setScale()
  }

  isRange (start, finish) {
    const axis = this.xAxis
    return start === axis.dataMin && finish === axis.dataMax
  }

  setRange (start, finish) {
    if (this.isRange(start, finish)) {
      // Chart is already at this range
      return
    }

    const axis = this.xAxis
    axis.setExtremes(start, finish, true, false)

    const resetZoomButton = this.highcharts.resetZoomButton

    if (!resetZoomButton) {
      this.highcharts.showResetZoom()
    } else if (resetZoomButton && start <= axis.dataMin && finish >= axis.dataMax) {
      this.highcharts.resetZoomButton = resetZoomButton.destroy()
    }
  }

  highlight (from, to) {
    return this.addPlotBand('highlight', 'rgba(255, 255, 255, 0.05)', from, to)
  }

  unhighlight () {
    return this.removePlotBand('highlight')
  }

  addPlotBand (name, color, from, to, zIndex) {
    return this.xAxis.addPlotBand({
      id: name,
      color: color,
      from: from,
      to: to,
      zIndex: zIndex || 10
    })
  }

  removePlotBand (name) {
    return this.xAxis.removePlotBand(name)
  }

  setPlotBand (name, color, from, to) {
    this.removePlotBand(name)
    this.addPlotBand(name, color, from, to)
  }

  addPlotBandY (name, from, to, options) {
    return this.yAxis.addPlotBand({
      id: name,
      from: from,
      to: to,
      ...options
    })
  }

  removePlotBandY (name) {
    return this.yAxis.removePlotBand(name)
  }

  setPlotBandY (name, color, from, to) {
    this.removePlotBandY(name)
    this.addPlotBandY(name, color, from, to)
  }

  clear () {
    while (this.highcharts.series.length > 0) {
      this.highcharts.series[0].remove(false)
    }
  }

  createPlotLine (timestamp, options) {
    if (timestamp > 0) {
      return {
        ...options,
        value: parseInt(timestamp),
        width: 1
      }
    }
  }

  get xAxis () {
    return this.highcharts.xAxis[0]
  }

  get yAxis () {
    return this.highcharts.yAxis[0]
  }
}

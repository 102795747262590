import $ from 'jquery'

import Tooltips from './tooltips'

$(document).on('click', '[data-behavior="next-page"]', function (e) {
  e.preventDefault()

  const url = $(this).attr('href')

  const wrapper = $(this).data('wrapper')
  const item = wrapper ? $(this).closest(wrapper) : $(this).parent()

  const container = item.parent()

  $(this).addClass('loading').text('Loading...')

  $.get(url, function (data) {
    let page = $(data)

    const firstHeader = page[0].textContent
    if (container.find(`.header:contains(${firstHeader})`).length) {
      page = page.slice(1)
    }

    item.replaceWith(page)

    Tooltips.init()
  })
})

import $ from 'jquery'

export default class Print {
  constructor (el, options = {}) {
    this.el = el
    this.$el = $(el)

    this.options = options

    if (options.mirror) {
      this.mirror = $(options.mirror)
    }
  }

  show () {
    // Show container first
    this.$el.show().addClass('loading')

    // Show SVGs after
    setTimeout(() => {
      this.$el.find('svg').show()
      this.$el.removeClass('loading')
    }, 1)
  }

  hide () {
    this.$el.hide().find('svg').hide()
  }

  remove () {
    this.$el.remove()
  }

  setLoading (loading) {
    this.$el.toggleClass('loading', loading)

    if (this.mirror) {
      this.mirror.toggleClass('loading', loading)
    }
  }

  updateMirror () {
    if (this.mirror) {
      const clone = this.$el.children().clone()
      this.mirror = this.mirror.html(clone)

      const canvases = this.$el.find('canvas')
      const newCanvases = clone.find('canvas')

      canvases.each(function (index) {
        const source = this
        const target = newCanvases[index].getContext('2d')

        target.drawImage(source, 0, 0)
      })
    }
  }

  buildSizer (width, height) {
    return $('<img>').attr('src', this.sizerSrc(width, height)).attr('class', 'sizer')
  }

  sizerSrc (width, height) {
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}"><rect width="${width}" height="${height}" /></svg>`
  }
}

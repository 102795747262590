import $ from 'jquery'
import tinycolor from 'tinycolor2'

import MetricData from './data'
import Series from 'runscribe/charts/series'
import BoxPlotSeries from 'runscribe/charts/box_plot_series'
import Utils from 'runscribe/utils'

export default class Metric {
  constructor (data, options) {
    this.data = data

    this.name = data.name
    this.label = data.label
    this.axis = data.axis
    this.units = data.units
    this.convert = data.convert

    this.formatter = data.formatter
    this.precision = data.precision
    this.binWidth = data.binWidth

    this.color = options.color
    this.alternateColor = tinycolor(this.color).darken(20).toString()

    this.onShow = options.onShow || function () {}
    this.onHide = options.onHide || function () {}
    this.onUpdate = options.onUpdate || function () {}
  }

  format (value) {
    return this.formatter(value, this.precision)
  }

  runSeries (chart, options) {
    const metric = this

    if (this.name === 'stride_pace') {
      options = $.extend({}, {
        lineWidth: 1,
        dashStyle: 'ShortDot'
      }, options)
    }

    const data = this.data.combined
    const series = new Series(chart, $.extend({}, {
      timestamps: data.timestamps,
      values: data.values,
      average: (this.name === 'stride_pace' ? false : Utils.average(data.values)),
      smoothed: true,
      series: options,
      color: metric.color,
      label: metric.label,
      name: metric.name,
      units: metric.units,
      axis: metric.axis,
      convert: metric.convert,
      formatter: metric.format.bind(metric),
      onShow: $.proxy(metric.onShow, metric),
      onHide: $.proxy(metric.onHide, metric)
    }, options))

    return [series]
  }

  lapsSeries (chart, options) {
    const metric = this

    let markers = Object.keys(chart.laps).map((i) => parseInt(i))
    markers = markers.slice(1, markers.length - 1)

    const splits = this.data.splits(markers)

    const stats = []
    $.each(splits, function (i) {
      stats[i] = metric.data.quartiles(this)
    })

    const series = new BoxPlotSeries(chart, $.extend({}, {
      data: stats,
      color: metric.color,
      label: metric.label,
      name: `${metric.name}-laps`,
      units: metric.units,
      axis: metric.axis,
      convert: metric.convert,
      formatter: metric.format.bind(metric),
      splitLabel: 'Lap',
      splitMarkers: chart.laps,
      onShow: $.proxy(metric.onShow, metric),
      onHide: $.proxy(metric.onHide, metric)
    }, options))

    return [series]
  }

  splitsSeries (chart, options) {
    const metric = this

    let markers = Object.keys(chart.activeMarkers).map((i) => parseInt(i))
    markers = markers.slice(1, markers.length - 1)

    const splits = this.data.splits(markers)

    const stats = []
    $.each(splits, function (i) {
      stats[i] = metric.data.quartiles(this)
    })

    const series = new BoxPlotSeries(chart, $.extend({}, {
      data: stats,
      color: metric.color,
      label: metric.label,
      name: `${metric.name}-splits`,
      units: metric.units,
      axis: metric.axis,
      convert: metric.convert,
      formatter: metric.format.bind(metric),
      splitLabel: 'Split',
      splitMarkers: chart.activeMarkers,
      onShow: $.proxy(metric.onShow, metric),
      onHide: $.proxy(metric.onHide, metric)
    }, options))

    return [series]
  }

  symmetrySeries (chart, options) {
    const metric = this

    if (this.data.symmetry) {
      const symmetry = this.data.symmetry

      const series = new Series(chart, $.extend({}, {
        timestamps: symmetry.timestamps,
        values: symmetry.values,
        average: 0,
        smoothed: true,
        color: metric.color,
        label: metric.label,
        name: `${metric.name}-symmetry`,
        units: metric.units,
        axis: `${metric.axis}-symmetry`,
        convert: metric.convert,
        formatter: metric.format.bind(metric),
        onShow: $.proxy(metric.onShow, metric),
        onHide: $.proxy(metric.onHide, metric)
      }, options))

      return [series]
    }
  }

  leftrightSeries (chart, options) {
    const result = []

    if (this.data.left) {
      const left = new Series(chart, {
        ...this.leftOptions(),
        options
      })

      result.push(left)
    }

    if (this.data.right) {
      const right = new Series(chart, {
        ...this.rightOptions(),
        options
      })

      result.push(right)
    }

    return result
  }

  footSeriesOptions (data) {
    return {
      timestamps: data.timestamps,
      values: data.values,
      average: 0,
      smoothed: true,
      color: this.color,
      units: this.units,
      axis: this.axis,
      convert: this.convert,
      formatter: this.format.bind(this),
      onShow: $.proxy(this.onShow, this),
      onHide: $.proxy(this.onHide, this)
    }
  }

  leftOptions () {
    return {
      ...this.footSeriesOptions(this.data.leftResampled),
      label: `${this.label} (L)`,
      name: `${this.name}-left`
    }
  }

  rightOptions () {
    return {
      ...this.footSeriesOptions(this.data.rightResampled),
      label: `${this.label} (R)`,
      name: `${this.name}-right`,
      color: this.alternateColor
    }
  }

  navigatorOptions () {
    return {
      id: this.name,
      data: this.data.zipped(),
      name: this.label,
      color: this.color,
      lineWidth: 3,
      zIndex: 2,
      states: { hover: { enabled: false } },
      connectNulls: true,
      dataGrouping: {
        groupPixelWidth: 10,
        smoothed: true,
        units: [
          ['second', [5, 10, 15, 30]],
          ['minute', [1, 2, 5, 10, 15, 30]]
        ]
      }
    }
  }

  updateStats (min, max) {
    const payload = this.data.stats(min, max)

    if (this.onUpdate) {
      this.onUpdate(this, payload)
    }
  }

  static load (url, options) {
    $.getJSON(url, function (result) {
      const data = new MetricData(result)
      const metric = new Metric(data, options)

      if (options.onLoad) {
        options.onLoad(metric)
      }
    })
  }
}

import $ from 'jquery'

const TEMPLATE = `
<div id="dialog" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
    </div>
  </div>
</div>
`

function createElement () {
  const el = $(TEMPLATE).appendTo('body')

  el.modal({ show: false })

  // Remove dialog elements once hidden
  el.on('hidden.bs.modal', function () {
    el.remove()
  })

  // Restore the next visible modal to the foreground
  el.on('hide.bs.modal', function () {
    el.prevAll('.modal.in').last().removeClass('background')
  })

  return el
}

export default class Dialog {
  constructor (options = {}) {
    this.options = options
    this.el = createElement()

    if (options.modalClass) {
      this.el.find('.modal-dialog').addClass(options.modalClass)
    }

    this.el.on('shown.bs.modal', () => this.focus())
  }

  load (url, data = {}) {
    this.empty()
    this.show()
    this.loading(true)

    $.ajax({
      url,
      data,
      success: (content) => {
        this.loading(false)
        this.content(content)
        this.setupEvents()
        this.focus()
      },
      error: (xhr, status, error) => {
        this.loading(false)
      }
    })
  }

  content (content) {
    this.el.find('.modal-content').html(content)
  }

  empty () {
    this.el.find('.modal-content').empty()
  }

  show () {
    this.el.modal('show')
  }

  hide () {
    this.el.modal('hide')
  }

  loading (loading) {
    this.el.toggleClass('loading', loading)
  }

  getField (attribute) {
    return this.form.find(`[data-attribute="${attribute}"]`)
  }

  setupEvents () {
    this.form
      .on('ajax:before', () => {
        this.loading(true)
      }).on('ajax:error', (event) => {
        const [response, status, xhr] = event.detail
        this.onError(response)
      }).on('ajax:success', (event) => {
        const [response, status, xhr] = event.detail
        this.onSuccess(response, status, xhr)
      }).on('ajax:complete', (event) => {
        const [xhr, status] = event.detail
        this.onComplete(xhr, status)
      })

    return this.el.find('[data-method="delete"]')
      .on('ajax:success', (event) => {
        event.stopPropagation()

        const [response, status, xhr] = event.detail
        this.onDelete(response, status, xhr)
      })
  }

  onSuccess (data, status, xhr) {
    if (data.location) {
      this.redirect(data.location, data.dialog)
    }

    this.hide()
  }

  onComplete (xhr, status) {
    this.loading(false)
  }

  onDelete (data, status, xhr) {
    if (data.location) {
      this.redirect(data.location, data.dialog)
    } else {
      this.hide()
    }
  }

  onError (errors) {
    this.clearErrors()
    this.addErrors(errors)
  }

  get form () {
    return this.el.find('form')
  }

  get body () {
    return this.el.find('.modal-body')
  }

  focus () {
    this.body.find(':input:visible:first').focus()
  }

  redirect (url, dialog) {
    if (dialog) {
      dialog = new Dialog()
      dialog.load(url)
    } else {
      document.location.href = url
    }
  }

  clearErrors () {
    this.form
      .find('.has-error').removeClass('has-error').end()
      .find('.form-error').remove()
  }

  addError (attribute, errors) {
    const field = this.getField(attribute)
    const group = field.closest('.form-group')

    group.addClass('has-error')

    if (group.find('.form-error').length === 0) {
      group.append('<p class="help-block form-error">')
    }

    group.find('.form-error').text(errors[0])
  }

  addErrors (errors) {
    $.each(errors, (attribute, errors) => {
      this.addError(attribute, errors)
    })
  }
}

$(document).on('click', '[data-dialog]', function (e) {
  e.stopPropagation()
  e.preventDefault()

  const dialog = new Dialog({
    class: $(this).data('dialog-class')
  })

  dialog.load($(this).data('url') || $(this).attr('href'))
})

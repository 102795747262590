import $ from 'jquery'

import Chart from './chart'

export default class SplitChart extends Chart {
  constructor (id, options) {
    const defaults = {
      chart: {
        zoomType: null,
        pinchType: null,
        panning: false
      },
      tooltip: { enabled: false },
      xAxis: [{
        min: 0
      }],
      yAxis: {
        gridLineColor: '#545358',
        labels: {
          format: '{value}<br />s/min'
        },
        opposite: false
      },
      plotOptions: {
        series: {
          enableMouseTracking: false
        }
      }
    }

    super(id, { options: $.extend(true, {}, defaults, options) })
  }
}

import $ from 'jquery'

import Utils from './utils'
import ShoePrint from './prints/shoe_print'
import Autosize from './prints/autosize'

function createSparkline (el) {
  const $el = $(el)

  const values = $el.data('values')
  const units = $el.data('units')
  const format = $el.data('format')

  const formatter = Utils.formatter(format)

  $el.sparkline(values, {
    type: 'line',
    width: '100%',
    height: '40px',
    spotRadius: 4,
    fillColor: 'transparent',
    lineColor: '#f1f954',
    spotColor: 'red',
    formatter: Utils.formatFloat,
    tooltipFormatter: function (sparkline, options, value) {
      return `<div class="jqsfield">${formatter(value.y)} <span class="units">${units}</span></div>`
    }
  })
}

export default class Shoes {
  constructor (options) {
    this.constructor.updateSparklines()
    this.constructor.updatePrints()

    $('.tag-filters input').on('change', function () {
      $.get(document.location, $('.tag-filters input:checked').serialize())
    })
  }

  static updateSparklines () {
    $('.sparkline').each(function () {
      createSparkline(this)
    })
  }

  static updatePrints () {
    const prints = $($('.print').get().reverse())

    prints.each(function () {
      const print = new ShoePrint(this)

      print.loadFromURL($(this).data('footstrike'))
      print.loadFromURL($(this).data('toe-off'))

      new Autosize(this).setup()
    })
  }
}

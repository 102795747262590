import $ from 'jquery'

import SplitChart from './split_chart'
import NavigatorChart from './navigator_chart'

import Metric from '../metric/metric'

const WINDOW_IN_MINUTES = 4
const WINDOW_IN_MILLISECONDS = WINDOW_IN_MINUTES * 60 * 1000

export default class CalibrationChart {
  constructor (container, options) {
    this.container = container

    this.onLoad = options.onLoad || function () {}
    this.onChange = options.onChange || function () {}

    this.setupNavigator()
    this.setupCharts()

    this.loadMetric(container.data('url'))
  }

  setupNavigator () {
    var chart = this

    this.navigator = new NavigatorChart(this.container.find('.navigator'), {
      xAxis: [{
        events: {
          setExtremes: function (e) {
            const range = WINDOW_IN_MILLISECONDS / 2

            var xData = chart.finish.highcharts.series[0].xData
            var xMax = xData[xData.length - 1]

            chart.start.highcharts.xAxis[0].setExtremes(Math.max(0, e.min - range), Math.min(e.min + range, xMax))
            chart.start.setPlotBand('highlight', 'rgba(0, 0, 0, 0.25)', 0, e.min)

            chart.finish.highcharts.xAxis[0].setExtremes(Math.max(0, e.max - range), Math.min(e.max + range, xMax))
            chart.finish.setPlotBand('highlight', 'rgba(0, 0, 0, 0.25)', e.max, xMax)

            chart.onChange(e.min, e.max)
          }
        }
      }]
    })
  }

  setupCharts () {
    this.start = new SplitChart(this.container.find('.start'), {
      yAxis: {
        opposite: false
      }
    })

    this.finish = new SplitChart(this.container.find('.finish'), {
      yAxis: {
        labels: $(window).width() > 768 ? { enabled: false } : { align: 'left' }
      }
    })
  }

  loadMetric (url) {
    const chart = this

    const start = this.start.highcharts
    const finish = this.finish.highcharts
    const navigator = this.navigator.highcharts

    Metric.load(url, {
      onLoad: function (metric) {
        const options = metric.navigatorOptions()

        start.addSeries(options)
        finish.addSeries(options)

        const min = metric.data.min()
        const max = metric.data.max()

        start.yAxis[0].setExtremes(min, max)
        finish.yAxis[0].setExtremes(min, max)

        navigator.addSeries(options)

        chart.onLoad(metric)
      }
    })
  }

  setRange (start, finish) {
    this.navigator.setRange(start, finish)
  }
}

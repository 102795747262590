import $ from 'jquery'

import 'fullcalendar'

const POPOVER_TEMPLATE = `
  <div class="popover calendar-popover arrow" role="tooltip">
    <h3 class="popover-title"></h3>
    <div class="popover-content"></div>
  </div>`

export default class {
  constructor (el) {
    el.fullCalendar({
      events: el.data('url'),

      header: {
        left:   'title',
        center: '',
        right:  'prev,next'
      },

      contentHeight: 450,

      eventMouseover: this.eventMouseover,
      eventMouseout: this.eventMouseout,

      buttonIcons: {
        prev: 'prev fa fa-angle-left',
        next: 'next fa fa-angle-right'
      },

      allDayDefault: true
    })
  }

  eventMouseover (e, jsEvent, view) {
    let event = new Event(e)

    $(this).popover({
      placement: 'top',
      html:      true,
      template:  POPOVER_TEMPLATE,
      trigger:   'manual',
      title:     event.titleHtml,
      content:   event.contentHtml,
      container: 'body'
    }).popover('show')
  }

  eventMouseout (e, jsEvent, view) {
    $(this).popover('hide')
  }
}

class Event {
  constructor (data) {
    this.data = data
  }

  get title () { return this.data.title }
  get notes () { return this.data.notes }
  get distance () { return this.data.distance }
  get units () { return this.data.distanceUnits }
  get duration () { return this.data.runDuration }

  get terrainTypes () { return this.data.terrainTypes }
  get workoutTypes () { return this.data.workoutTypes }

  get titleHtml () {
    return `<strong>${this.title}</strong>
            ${this.distance} ${this.units} &nbsp; | &nbsp;${this.duration}`
  }

  get contentHtml () {
    return [
      this.notes || '',
      this.terrainTypesHtml,
      this.workoutTypesHtml
    ].filter(Boolean).join('')
  }

  get terrainTypesHtml () {
    if (!this.terrainTypes.length) return

    return `
      <strong>Terrain:</strong>
      ${this.tagList(this.terrainTypes, 'terrain-tags')}
    `
  }

  get workoutTypesHtml () {
    if (!this.workoutTypes.length) return

    return `
      <strong>Workout:</strong>
      ${this.tagList(this.workoutTypes, 'workout-tags')}
    `
  }

  tagList (tags, listClass) {
    return `
      <ul class="tags ${listClass}">
        ${tags.map((t) => `<li class="tag">${t}</li>`).join('')}
      </ul>`
  }
}

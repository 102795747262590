import Counter from './counter'
import Dialog from './dialog'
import './editable'
import './search'
import './select'
import './sort'
import './tabs'
import Tooltips from './tooltips'
import './pagination'
import './amplitude'

window.Counter = Counter
window.Dialog = Dialog
window.Tooltips = Tooltips

import $ from 'jquery'

// global vars
var $pageBlocker, $messageContainer
var onboardingElements = []
var activeElement = 0

export default class Onboarding {
  constructor () {
    $pageBlocker = $('#pageBlocker')
    $messageContainer = $('#welcome-message-container')

    setTimeout(function () {
      blurPage()
      initWelcomeSequence()
    }, 1000)

    // click on the start tour btn
    $('.start-onboarding-btn').on('click', performWelcomeSequence)

    // click next or previous to page through the messages
    $('.next-btn').on('click', showNextMessage)
    $('.previous-btn').on('click', showPreviousMessage)

    // enter button listener to paginate forward through sequence
    $('body').on('keypress', function (e) {
      if (e.which === 13) {
        showNextMessage()
      }
    })

    // click listener to end the sequence
    $('body').on('click', '.finish-onboard, .close-onboard-btn', focusPage)

    $pageBlocker.on('click', focusPage)
  }
}

// generates an array of elements that need to be "highlighted" as part of the initial onboarding messaging
// and initializes the message area
function initWelcomeSequence () {
  $('.onboard-message').each(function () {
    onboardingElements.push($(this))
  })

  $messageContainer.fadeIn('slow')
}

// shows the first tooltip and highlights the first region
// and is called each time to go back and forward around the areas to help
function performWelcomeSequence () {
  window.scrollTo(0, 0)

  $messageContainer.removeClass('slowshake')
  $('.onboard-message').removeClass('spotlight')
  $('.button-container').css('display', 'block')

  var $element = onboardingElements[activeElement]
  $element.addClass('spotlight')
  moveMessageContainer($element)
  renderMessage($element)
}

// this grabs the relevant words from the element and populates the message area
function renderMessage ($element) {
  $messageContainer.children('.content').html($element.data('welcome'))
  displayPaginationButtons()
}

// this moves the message container to the bottom and left aligns with the element highlighted
function moveMessageContainer ($element) {
  var top = $element.offset().top + $element.height() / 2 + 50
  var left = $element.offset().left + $element.width() / 2 - 50
  $messageContainer.css('top', top + 'px').css('left', left + 'px')
}

// go forward one message
function showNextMessage () {
  activeElement++
  performWelcomeSequence()
}

// go back one message
function showPreviousMessage () {
  activeElement--
  performWelcomeSequence()
}

// check to show buttons
function displayPaginationButtons () {
  if (activeElement === onboardingElements.length - 1) {
    $('.button-container').html('<div style="padding:10px 40px;margin-right:10px;width:100%;" class="finish-onboard btn btn-lg btn-success pull-left">GET STARTED</div>')
  }
}

// view actions for major page blockers
function blurPage () {
  $pageBlocker.fadeIn('slow')
}

function focusPage () {
  onboardAccount()
  $pageBlocker.remove()
  $messageContainer.remove()
  $('body').off('keypress')
}

// sets the current account is_onboarded flag to true for the current account
function onboardAccount () {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  $.ajax({
    type: 'POST',
    url: '/account/complete',
    headers: {
      'X-CSRF-Token': csrfToken
    }
  })
}

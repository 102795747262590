/* global RunScribe */

import $ from 'jquery'

import Utils from 'runscribe/utils'

const yAxisOptions = {
  'G': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}G',
      align: 'left'
    }
  },
  'ms': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}ms'
    }
  },
  'm': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}m',
      align: 'left'
    }
  },
  'cm': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}cm',
      align: 'left'
    }
  },
  'ft': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}ft',
      align: 'left'
    }
  },
  'F(Bw)': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}F(Bw)',
      align: 'left'
    }
  },
  's/min': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}<br />s/min'
    }
  },
  'm/s': {
    gridLineColor: '#545358',
    labels: {
      format: '{value} m/s'
    },
    opposite: false
  },
  'min/km': {
    gridLineColor: '#545358',
    labels: {
      formatter: Utils.paceFormatter
    },
    opposite: false,
    reversed: true
  },
  'min/mi': {
    gridLineColor: '#545358',
    labels: {
      formatter: Utils.paceFormatter
    },
    opposite: false,
    reversed: true
  },
  '°': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}°',
      align: 'left'
    }
  },
  '°/s': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}°/s',
      align: 'left'
    }
  },
  'W': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}W',
      align: 'left'
    }
  },
  'FS': {
    gridLineColor: '#87868b',
    labels: {
      formatter: function () { return Math.floor(this.value) },
      align: 'left',
      y: 5
    },
    tickPositions: [0, 6, 12, 16],
    showLastLabel: true,
    visible: false,
    plotBands: [
      {
        color: 'rgba(255, 255, 255, 0.025)',
        from: 0,
        to: 6,
        label: { text: 'Heel', align: 'right', x: -10, style: { color: '#ccc' } }
      },
      {
        color: 'rgba(255, 255, 255, 0.05)',
        from: 6,
        to: 12,
        label: { text: 'Mid-foot', align: 'right', x: -10, style: { color: '#ccc' } }
      },
      {
        color: 'rgba(255, 255, 255, 0.1)',
        from: 12,
        to: 16,
        label: { text: 'Fore-foot', align: 'right', x: -10, style: { color: '#ccc' } }
      }
    ]
  },
  'FS-new': {
    gridLineColor: '#87868b',
    labels: {
      formatter: function () { return Math.floor(this.value) },
      align: 'left',
      y: 5
    },
    tickPositions: [0, 3, 7, 10],
    showLastLabel: true,
    visible: false,
    plotBands: [
      {
        color: 'rgba(255, 255, 255, 0.025)',
        from: 0,
        to: 3,
        label: { text: 'Heel', align: 'right', x: -10, style: { color: '#ccc' } }
      },
      {
        color: 'rgba(255, 255, 255, 0.05)',
        from: 3,
        to: 7,
        label: { text: 'Mid-foot', align: 'right', x: -10, style: { color: '#ccc' } }
      },
      {
        color: 'rgba(255, 255, 255, 0.1)',
        from: 7,
        to: 10,
        label: { text: 'Fore-foot', align: 'right', x: -10, style: { color: '#ccc' } }
      }
    ]
  },
  '%': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}%',
      align: 'left'
    }
  },
  'kN/m': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}kN/m',
      align: 'left'
    }
  },
  'N/kg/s': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}N/kg/s',
      align: 'left'
    }
  },
  'ml/kg/min': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}ml/kg/min',
      align: 'left'
    }
  },
  '': {
    gridLineColor: '#545358',
    labels: {
      format: '{value}',
      align: 'left'
    }
  }
}

// Create symmetry version of each axis
$.each(yAxisOptions, function (key, value) {
  yAxisOptions[`${key}-symmetry`] = $.extend({}, value, {
    visible: false,
    plotBands: [
      {
        color: 'rgba(255, 255, 255, 0.0025)',
        from: -10000,
        to: 0,
        label: { text: 'Left', align: 'right', verticalAlign: 'bottom', x: -10, y: -10, style: { color: '#ccc' } }
      },
      {
        color: 'rgba(255, 255, 255, 0.05)',
        from: 0,
        to: 10000,
        label: { text: 'Right', align: 'right', verticalAlign: 'top', x: -10, y: 10, style: { color: '#ccc' } }
      }
    ]
  })

  yAxisOptions[`${key}-symmetry`].tickPositions = undefined
})

const defaults = {
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  credits: { enabled: false },
  legend: { enabled: false },
  scrollbar: { enabled: false },
  title: {
    text: undefined
  },
  chart: {
    zoomType: 'x',
    panning: true,
    panKey: 'shift',
    backgroundColor: 'transparent',
    type: 'spline',
    resetZoomButton: {
      theme: {
        fill: 'transparent',
        stroke: 'transparent',
        style: {
          color: '#57d6ac'
        },
        r: 0,
        states: {
          hover: {
            fill: '#29292a',
            stroke: 'transparent',
            style: {
              color: '#57d6ac'
            }
          }
        }
      }
    }
  },
  xAxis: [{
    lineColor: '#545358',
    tickLength: 0,
    ordinal: false,
    labels: {
      formatter: Utils.timeFormatter,
      step: 1
    },
    minPadding: 0,
    maxPadding: 0
  }],
  yAxis: $.map(yAxisOptions, function (value) { return value }),
  loading: {
    style: {
      backgroundColor: 'black'
    }
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    style: { color: '#eee' },
    useHTML: true,
    formatter: function () {
      const points = this.points ? this.points : [this]

      let header = ''
      let body = ''

      if (points[0].point.median) {
        // Box plot split
        const label = points[0].series.tooltipOptions.splitLabel
        header = `<b>${label} #${points[0].point.index + 1}:</b>`
      } else {
        // Line
        header = `<b>Time:</b> ${Utils.formatTime(this.x)}`
      }

      $.each(points, function () {
        var units = this.series.tooltipOptions.valueSuffix
        var formatter = this.series.tooltipOptions.formatter || (Utils.isPaceUnits(units) ? Utils.formatPace : Utils.formatFloat)

        if (this.point.median) {
          // Box plot
          var min = formatter(this.point.low)
          var q1 = formatter(this.point.q1)
          var median = formatter(this.point.median)
          var q3 = formatter(this.point.q3)
          var max = formatter(this.point.high)

          var markers = this.series.tooltipOptions.splitMarkers || {}
          var splits = $.map(Object.keys(markers), function (i) { return parseInt(i) })

          var from, to
          for (var i = 1; i < splits.length; ++i) {
            if (this.point.x > splits[i - 1] && this.point.x < splits[i]) {
              from = splits[i - 1]
              to = splits[i]
              break
            }
          }

          var fromDistance = markers[from]
          var toDistance = markers[to]

          header += ` (${Utils.formatDistanceRange(fromDistance, toDistance)})`

          body += '<table style="width: 100%">'
          body += `<tr><th style="padding-right: 10px; color: ${this.series.color}">${this.series.name}:</th><td style="text-align: right;"><span style="color: #6b6565">${units}</span></td></tr>`
          body += `<tr><th>Minimum</th><td style="text-align: right">${min}</td>`
          body += `<tr><th>Q1</th><td style="text-align: right">${q1}</td>`
          body += `<tr><th>Median</th><td style="text-align: right">${median}</td>`
          body += `<tr><th>Q3</th><td style="text-align: right">${q3}</td>`
          body += `<tr><th>Maximum</th><td style="text-align: right">${max}</td>`
          body += '</table>'
        } else {
          var value = formatter(this.y)

          body += `<p style="margin: 0;"><b style="color: ${this.series.color}">${this.series.name}:</b> `
          body += `${value} <span style="color: #6b6565">${units}</span></p>`
        }
      })

      return `<div style="border-bottom: 1px solid rgba(255, 255, 255, 0.2); padding-bottom: 4px; margin-bottom: 4px;">${header}</div>${body}`
    }
  }
}

export { yAxisOptions, defaults }
export default defaults

import Highcharts from 'highcharts/highstock'

Highcharts.SVGRenderer.prototype.symbols.leftarrow = function (x, y, w, h) {
  return [
    'M', x + w / 2, y,
    'L', x + w / 2, y + h,
    x - w / 2, y + h / 2,
    'Z'
  ]
}

Highcharts.SVGRenderer.prototype.symbols.rightarrow = function (x, y, w, h) {
  return [
    'M', x + w / 2, y,
    'L', x + w / 2, y + h,
    x + w + w / 2, y + h / 2,
    'Z'
  ]
}

if (Highcharts.VMLRenderer) {
  Highcharts.VMLRenderer.prototype.symbols.leftarrow = Highcharts.SVGRenderer.prototype.symbols.leftarrow
  Highcharts.VMLRenderer.prototype.symbols.rightarrow = Highcharts.SVGRenderer.prototype.symbols.rightarrow
}

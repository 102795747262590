import $ from 'jquery'
import 'select2/dist/js/select2.full'

// Fix select2 within Bootstrap modals
$.fn.modal.Constructor.prototype.enforceFocus = () => { }

// Fix seach field focus bug with jQuery 3.6.0
// See https://github.com/select2/select2/issues/5993
$(document).on('select2:open', () => {
  document.querySelector('.select2-container--open .select2-search__field').focus();
});

$(function () {
  // Automatically switch to option value URL when selected
  $('select[data-behavior="autoswitch"]').each(function () {
    $(this).on('change', function () {
      var selected = $(this).find('option:selected')
      document.location.href = selected.val()
    })
  })
})


import $ from 'jquery'

import Utils from 'runscribe/utils'

function zip (array1, array2) {
  var result = []
  for (var i = 0; i < array1.length; ++i) {
    result.push([array1[i], array2[i]])
  }
  return result
}

function isNumeric (n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

function combineFunction (method) {
  if (method === 'minimum') {
    return Utils.min
  } else if (method === 'maximum') {
    return Utils.max
  } else {
    return Utils.average
  }
}

export default class MetricData {
  constructor (data) {
    this.name = data.name
    this.label = data.label
    this.units = data.units
    this.axis = data.axis

    this.format = data.format
    this.precision = data.precision
    this.binWidth = data.binWidth

    this.formatter = Utils.formatter(this.format)

    this.combine = combineFunction(data.combine)

    this.dataUnits = data.mountings[0].units
    this.convert = Utils.conversion(this.dataUnits, this.units)
    this.convertAll = (values) => values.map(v => this.convert(v))

    this.unified = data.unified

    this.mountings = data.mountings

    this.left = data.mountings.filter((m) => m.foot === 'left')[0]
    if (this.left) {
      this.leftResampled = Utils.resample(this.left, 1000, this.combine)
    }

    this.right = data.mountings.filter((m) => m.foot === 'right')[0]
    if (this.right) {
      this.rightResampled = Utils.resample(this.right, 1000, this.combine)
    }

    if (this.left && this.right) {
      this.symmetry = Utils.merge(this.leftResampled, this.rightResampled, (left, right) => right - left)

      this.combined = Utils.merge(this.leftResampled, this.rightResampled, (left, right) => {
        if (isNumeric(left) && isNumeric(right)) {
          return this.combine([left, right])
        } else if (isNumeric(left)) {
          return left
        } else if (isNumeric(right)) {
          return right
        }
      })
    } else if (this.left) {
      this.combined = this.left
    } else if (this.right) {
      this.combined = this.right
    }

    if (data.mountings[0].distances) {
      var leftDistances = data.mountings
        .filter(function (mounting) { return mounting.foot === 'left' })
        .map(function (mounting) { return Utils.resample(mounting.distances, 1000) })[0]

      var rightDistances = data.mountings
        .filter(function (mounting) { return mounting.foot === 'right' })
        .map(function (mounting) { return Utils.resample(mounting.distances, 1000) })[0]

      if (leftDistances && rightDistances) {
        this.distances = Utils.merge(leftDistances, rightDistances, function (left, right) {
          if (left && right) {
            return (left + right) / 2
          } else if (left) {
            return left
          } else if (right) {
            return right
          }
        })
      } else if (leftDistances) {
        this.distances = leftDistances
      } else if (rightDistances) {
        this.distances = rightDistances
      }
    }
  }

  zipped () {
    return zip(this.combined.timestamps, this.combined.values)
  }

  min () {
    return Math.min.apply(null, this.combined.values)
  }

  max () {
    return Math.max.apply(null, this.combined.values)
  }

  splits (markers) {
    const data = this.combined
    const lastTimestamp = data.timestamps[data.timestamps.length - 1]

    const splits = []

    let t = 0

    for (let i = 0; i < markers.length + 1; ++i) {
      const marker = markers[i] || lastTimestamp
      if (!marker) continue

      const split = splits[i] = {
        timestamp: (marker + (markers[i - 1] || 0)) / 2,
        range: (marker - (markers[i - 1] || 0)),
        values: []
      }

      while (t < data.timestamps.length && (data.timestamps[t] < marker)) {
        split.values.push(data.values[t])
        ++t
      }
    }

    for (let i = 0; i < splits.length; ++i) {
      const split = splits[i]
      split.scale = splits.length * split.range / lastTimestamp
    }

    return splits
  }

  quartiles (split) {
    var sorted = split.values.sort(function (a, b) { return a - b })
    var outlierRange = Math.floor(sorted.length * 0.05)

    return {
      x: split.timestamp,
      low: sorted[outlierRange],
      q1: sorted[Math.floor(sorted.length * 1 / 4)],
      median: sorted[Math.floor(sorted.length / 2)],
      q3: sorted[Math.floor(sorted.length * 3 / 4)],
      high: sorted[sorted.length - outlierRange - 1],
      z: split.scale
    }
  }

  valuesBetween (data, min, max) {
    const range = this.timestampIndexes(data, min, max)
    return $.grep(data.values.slice(range[0], range[1] + 1), function (n) { return n !== null && !isNaN(n) })
  }

  timestampIndexes (data, from, to) {
    let fromIndex = 0
    let toIndex = data.timestamps.length - 1

    if (from && to) {
      while (fromIndex < data.timestamps.length - 1 && data.timestamps[fromIndex] < from) {
        ++fromIndex
      }

      while (toIndex > 0 && data.timestamps[toIndex] >= to) {
        --toIndex
      }
    }

    return [fromIndex, toIndex]
  }

  stats (min, max) {
    var combinedValues = this.valuesBetween(this.combined, min, max)

    var payload = {
      range: { min: min, max: max },
      min: this.convert(Utils.min(combinedValues)),
      max: this.convert(Utils.max(combinedValues)),
      avg: this.convert(this.combine(combinedValues)),
      values: this.convertAll(combinedValues)
    }

    if (this.left) {
      var leftValues = this.valuesBetween(this.left, min, max)
      payload.left = this.convert(this.combine(leftValues))
    }

    if (this.right) {
      var rightValues = this.valuesBetween(this.right, min, max)
      payload.right = this.convert(this.combine(rightValues))
    }

    return payload
  }
}

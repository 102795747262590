import * as d3 from 'd3'

export const SHOE_POINTS = [
  [4, 0], [4.9, 3], [6, 5.5], [8, 11.5], [8.5, 17], [8, 22], [6, 24.5], [3, 25.3], [-1, 24.5], [-3, 23],
  [-5, 21], [-7, 18], [-7.8, 15], [-8, 11], [-7, 5], [-6, 0], [-5.5, -3], [-5.3, -5], [-5.2, -9], [-5.8, -17],
  [-5, -19], [-4, -21], [-2, -22], [0, -22], [2, -22], [3, -21], [4.9, -19], [5.2, -16], [5, -11], [4, -4], [4, -2]
]

export default class FootPrint {
  constructor (container, options = {}) {
    this.container = d3.select(container).append('div').attr('class', `foot ${options.foot}`)
    this.layers = this.container.append('div').attr('class', 'layers')

    this.enabled = options.enabled
    this.onUpdate = options.onUpdate || function () {}

    if (!this.enabled) {
      this.container.classed('disabled', true)
    }

    this.foot = options.foot
    this.lr = (this.foot === 'left' ? 1 : -1)

    this.width = options.width || 300
    this.height = options.height

    this.svg = this.layers
      .append('svg')
      .attr('viewBox', '-50 -110 100 240')
      .attr('preserveAspectRatio', 'xMidYMid meet')

    this.x = d3.scaleLinear().domain([-40, 40]).range([-40, 40])
    this.y = d3.scaleLinear().domain([-100, 120]).range([120, -100])

    this.renderGrid()
    this.renderOutline()
  }

  renderGrid () {
    const gridX = this.svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0, ${this.y(-100)})`)
      .call(d3.axisTop(this.x)
        .ticks(8)
        .tickSize(220))

    gridX.selectAll('line')
      .style('stroke', 'rgb(14%, 14%, 14%)')
      .style('stroke-dasharray', '1, 2')

    gridX.selectAll('path').remove()
    gridX.selectAll('text').remove()

    const gridY = this.svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${this.x(40)}, 0)`)
      .call(d3.axisLeft(this.y)
        .ticks(12)
        .tickSize(80))

    gridY.selectAll('line')
      .style('stroke', 'rgb(14%, 14%, 14%)')
      .style('stroke-dasharray', '1, 2')

    gridY.selectAll('path').remove()
    gridY.selectAll('text').remove()
  }

  renderOutline () {
    this.svg.append('g')
      .attr('class', 'outline')
      .selectAll('circle')
      .data(SHOE_POINTS)
      .enter()
      .append('circle')
      .attr('fill', 'green')
      .attr('r', 0.5)
      .attr('cx', (d) => this.x(d[0] * this.lr * 4.1))
      .attr('cy', (d) => this.y(d[1] * 4.1))
  }
}

import Calendar from './calendar'
import CalibrationView from './run/calibration'
import Charts from './charts'
import Dashboard from './dashboard'
import DebugView from './run/debug'
import Onboarding from './onboarding'
import Profile from './profile'
import Shoes from './shoes'
import RunList from './run/list'
import Utils from './utils'

import './lessons'
import './ui'

import './prints/autosize'

export default {
  Calendar,
  CalibrationView,
  Charts,
  Dashboard,
  DebugView,
  Onboarding,
  Profile,
  Shoes,
  RunList,
  Utils
}

import $ from 'jquery'

import * as d3 from 'd3'

import ShoePrintBase from './base/shoeprint'
import FootPrintBase from './base/footprint'

import Utils from 'runscribe/utils'

const pixelRatio = Utils.getPixelRatio()

export const METRICS = {
  // Footstrike

  shock: {
    type: 'footstrike',
    scale: d3.scaleLinear().domain([0, 20]).range([0, 0.8]).clamp(true),
    palette: d3.interpolateInferno,
    units: 'G'
  },

  max_pronation_velocity: {
    type: 'footstrike',
    scale: d3.scaleLinear().domain([0, 1600]).range([0, 0.8]).clamp(true),
    palette: d3.interpolateInferno,
    units: '°/s'
  },

  kleg: {
    type: 'footstrike',
    scale: d3.scaleLinear().domain([0, 15]).range([0, 0.8]).clamp(true),
    palette: d3.interpolateInferno,
    units: 'kN/m'
  },

  kvert: {
    type: 'footstrike',
    scale: d3.scaleLinear().domain([0, 30]).range([0, 0.8]).clamp(true),
    palette: d3.interpolateInferno,
    units: 'kN/m'
  },

  grf_vert: {
    type: 'footstrike',
    scale: d3.scaleLinear().domain([20, 60]).range([0, 0.8]).clamp(true),
    palette: d3.interpolateInferno,
    units: 'N/kg/s'
  },

  // Toe off

  flight_ratio: {
    type: 'toe_off',
    scale: d3.scaleLinear().domain([-5, 60]).range([0.3, 1]).clamp(true),
    palette: d3.interpolateViridis,
    units: '%'
  },

  power: {
    type: 'toe_off',
    scale: d3.scaleLinear().domain([0, 400]).range([0.3, 1]).clamp(true),
    palette: d3.interpolateViridis,
    units: 'W'
  },

  flight_time: {
    type: 'toe_off',
    scale: d3.scaleLinear().domain([0, 60]).range([0.3, 1]).clamp(true),
    palette: d3.interpolateViridis,
    units: 'ms'
  },

  grf_horiz: {
    type: 'toe_off',
    scale: d3.scaleLinear().domain([0, 15]).range([0.3, 1]).clamp(true),
    palette: d3.interpolateViridis,
    units: 'N/kg/s'
  },

  total_force_rate: {
    type: 'toe_off',
    scale: d3.scaleLinear().domain([80, 160]).range([0.3, 1]).clamp(true),
    palette: d3.interpolateViridis,
    units: 'N/kg/s'
  },

  vo2: {
    type: 'toe_off',
    scale: d3.scaleLinear().domain([30, 80]).range([0.3, 1]).clamp(true),
    palette: d3.interpolateViridis,
    units: 'ml/kg/min'
  }
}

export default class ShoePrint extends ShoePrintBase {
  buildFoot (options) {
    return new FootPrint(this.el, options)
  }

  fadeOut (type) {
    if (this.left) this.left.fadeOut(type)
    if (this.right) this.right.fadeOut(type)
  }

  hideType (type) {
    if (this.left) this.left.hideType(type)
    if (this.right) this.right.hideType(type)
  }

  loadMetric (metric, data) {
    if (this.left && data.left) this.left.loadMetric(metric, data.left)
    if (this.right && data.right) this.right.loadMetric(metric, data.right)
  }

  filter (filters) {
    if (this.left) this.left.filter(filters)
    if (this.right) this.right.filter(filters)
  }

  loadFromURL (url, type) {
    this.fadeOut(type)
    this.setLoading(true)

    this.fetch(url, (data) => {
      this.loadMetric(data.metric, data)
      this.setLoading(false)
    })
  }

  fetch (url, callback) {
    if (this.cache[url]) {
      callback(this.cache[url])
    } else {
      $.getJSON(url, (data) => {
        this.cache[url] = data
        callback(data)
      })
    }
  }
}

class FootPrint extends FootPrintBase {
  constructor (container, options = {}) {
    super(container, options)

    this.footstrike = null
    this.toeOff = null

    this.filters = {
      walking: true,
      running: true
    }

    this.data = {}
    this.points = {}
  }

  get footstrikeData () {
    return this.data[this.footstrike]
  }

  get toeOffData () {
    return this.data[this.toeOff]
  }

  loadMetric (metric, data) {
    if (!this.enabled) return

    this.data[metric] = data

    const config = METRICS[metric]
    if (!config) { throw new Error(`Invalid metric ${metric}`) }

    if (config.type === 'footstrike') {
      this.hide(this.footstrike)
      this.footstrike = metric

      this.renderData(this.footstrike, this.footstrikeData, config.scale, config.palette)
    } else if (config.type === 'toe_off') {
      this.hide(this.toeOff)
      this.toeOff = metric

      this.renderData(this.toeOff, this.toeOffData, config.scale, config.palette)
    }
  }

  hide (metric) {
    const points = this.points[metric]

    if (points) {
      points.running.remove()
      points.walking.remove()
    }

    this.onUpdate()
  }

  filter (filters) {
    for (const k in filters) {
      const enabled = filters[k]

      this.filters[k] = enabled

      const footstrike = this.pointsFor('footstrike')
      if (footstrike) footstrike[k].classed('hide', !enabled)

      const toeOff = this.pointsFor('toe_off')
      if (toeOff) toeOff[k].classed('hide', !enabled)
    }

    this.onUpdate()
  }

  hideType (type) {
    if (type === 'footstrike' && this.footstrike) {
      this.hide(this.footstrike)
      this.footstrike = null
    } else if (type === 'toe_off' && this.toeOff) {
      this.hide(this.toeOff)
      this.toeOff = null
    }

    this.onUpdate()
  }

  fadeOut (type) {
    const points = this.pointsFor(type)

    if (points) {
      points.running.attr('style', 'opacity: 0.1')
      points.walking.attr('style', 'opacity: 0.1')
    }

    this.onUpdate()
  }

  pointsFor (type) {
    if (type === 'footstrike' && this.footstrike) {
      return this.points[this.footstrike]
    } else if (type === 'toe_off' && this.toeOff) {
      return this.points[this.toeOff]
    }
  }

  renderData (metric, data, scale, color) {
    const steps = data.running.length + data.walking.length
    const alpha = Math.max(0.1, 2 / Math.sqrt(steps))

    const running = this.renderCanvas(data.running, scale, color, alpha)
    if (!this.filters.running) running.classed('hide', true)

    const walking = this.renderCanvas(data.walking, scale, color, alpha)
    if (!this.filters.walking) walking.classed('hide', true)

    this.points[metric] = {
      running: running,
      walking: walking
    }

    this.onUpdate()
  }

  renderCanvas (data, scale, color, alpha) {
    const canvas = this.layers
      .append('canvas')
      .attr('width', 300)
      .attr('height', 720)

    canvas.node().width *= pixelRatio
    canvas.node().height *= pixelRatio

    const ctx = canvas.node().getContext('2d')
    ctx.globalAlpha = alpha
    ctx.scale(pixelRatio, pixelRatio)

    const canvasX = d3.scaleLinear().domain([-50, 50]).range([0, canvas.node().width / pixelRatio])
    const canvasY = d3.scaleLinear().domain([-110, 130]).range([canvas.node().height / pixelRatio, 0])

    function drawPoint (x, y, color) {
      ctx.beginPath()
      ctx.fillStyle = color

      const px = canvasX(x)
      const py = canvasY(y)

      ctx.arc(px, py, 3, 0, 2 * Math.PI, true)
      ctx.closePath()
      ctx.fill()
    }

    const points = data.filter((d) => d[1] > -100 && d[1] <= 110)
    points.forEach((point) => drawPoint(point[0], point[1], color(scale(point[2]))))

    return canvas
  }
}

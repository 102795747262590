/* global RunScribe */

import $ from 'jquery'

import BasicSeries from './basic_series'

function hexToRGB (hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

export default class BoxPlotSeries extends BasicSeries {
  constructor (chart, options) {
    super(options)

    var series = this

    this.chart = chart

    this.convert = options.convert || function (v) { return v }
    this.convertAll = function (values) {
      var convert = this.convert
      values = $.extend({}, values)
      $.each(values, function (key, value) {
        if (key !== 'x') {
          values[key] = convert(value)
        }
      })
      return values
    }.bind(this)

    this.data = []
    $.each(options.data, function (i) {
      series.data[i] = series.convertAll(this)
    })

    this.name = options.name
    this.label = options.label
    this.color = options.color
    this.units = options.units
    this.axis = options.axis
    this.formatter = options.formatter

    this.splitLabel = options.splitLabel || 'Split'
    this.splitMarkers = options.splitMarkers || RunScribe.chart.activeMarkers

    this.initSeries()
  }

  initSeries () {
    const highcharts = this.chart.highcharts

    // Default box width (will be scaled for each box)
    const range = (highcharts.xAxis[0].max / this.data.length) * 0.75

    this.series = highcharts.addSeries({
      type: 'boxplot-z',
      id: this.name,
      color: hexToRGB(this.color, 0.75),
      fillColor: hexToRGB(this.color, 0.1),
      pointRange: range,
      pointPlacement: 'on',
      groupPadding: 0,
      pointPadding: 0,
      whiskerLength: 0,
      stemDashStyle: 'dot',
      data: this.data,
      name: this.label,
      tooltip: {
        valueSuffix: this.units,
        formatter: this.formatter,
        splitLabel: this.splitLabel,
        splitMarkers: this.splitMarkers
      },
      zIndex: 2,
      yAxis: this.yAxisIndex,
      states: { hover: { enabled: false } },
      visible: this.visible
    }, false)
  }
}

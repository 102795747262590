import $ from 'jquery'
import amplitude from 'amplitude-js'

const instance = amplitude.getInstance()
instance.init(process.env.AMPLITUDE_API_KEY)

$(function () {
  const userId = $('body').data('user-id')
  instance.setUserId(userId || 'guest')
})

$(document).on('click', '[data-amplitude]', function () {
  const eventName = $(this).data('amplitude')
  instance.logEvent(eventName)
})

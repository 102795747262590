/* global history */

import $ from 'jquery'

function doSearch (target, url, data) {
  target.find('table').addClass('loading')

  $.ajax({
    method: 'GET',
    url: url,
    data: data,
    success: function (data) {
      target.html(data)
    },
    beforeSend: function (xhr, settings) {
      history.replaceState({}, '', settings.url)
    }
  })
}

$(document).on('submit', '[data-behavior="search-table"]', function (e) {
  e.preventDefault()

  const form = $(this)
  const url = form.attr('action')

  const target = $(form.data('target'))

  doSearch(target, url, form.serialize())
})

$(document).on('click', '[data-behavior="clear-search"]', function (e) {
  e.preventDefault()

  const form = $(this).closest('form')
  const url = $(this).attr('href')

  const target = $(form.data('target'))

  form.find('.search-field').val('')

  doSearch(target, url)
})

import $ from 'jquery'

import Chart from '../charts/chart'
import PointSeries from '../charts/point_series'

import Utils from '../utils'

export default class DebugView {
  constructor () {
    this.timestamps = $('#pitch-chart').data('timestamps')
    this.steps = $('#pitch-chart').data('steps')

    this.setupChart('#pitch-chart')
    this.setupChart('#roll-chart')

    this.setupEvents()
  }

  setupEvents () {
    // Click handler for series toggle buttons
    $('.add-data-btn').on('click', function () {
      var series = $(this).data('series')
      series.toggle()
      series.chart.redraw()
      return false
    })

    // Pagination loading
    $('.steps-container').on('ajax:before', '[data-remote]', function () {
      $('.steps-table').addClass('loading')
    })
  }

  setupChart (selector) {
    const view = this

    function otherChart () {
      var target = (selector === '#pitch-chart') ? '#roll-chart' : '#pitch-chart'
      return $(target).highcharts()
    }

    var chart = new Chart(selector, {
      onZoom: function (event) {
        const chart = otherChart()

        if (chart && !chart.options.chart.isZoomed) {
          chart.options.chart.isZoomed = true
          chart.xAxis[0].setExtremes(event.min, event.max, true, false)
          chart.options.chart.isZoomed = false
        }

        if (event.trigger === 'zoom') {
          var table = $('.steps-table')
          var url = table.data('url')

          table.addClass('loading')

          $.ajax({
            url: url,
            data: {
              timestamp_from: event.min,
              timestamp_to: event.max
            },
            dataType: 'script'
          })
        }
      },

      options: {
        tooltip: {
          formatter: function () {
            let header = ''
            let body = ''

            let i = 0
            while (view.timestamps[i] < this.x) ++i

            header = '<b>Time:</b> ' + Utils.formatTime(this.x)
            header += '<br />'
            header += '<b>Step:</b> ' + view.steps[i]

            $('.steps-table tr').removeClass('highlight')
            $('.steps-table [data-step="' + view.steps[i] + '"]').addClass('highlight')

            $.each(this.points, function () {
              const units = this.series.tooltipOptions.valueSuffix
              const value = Utils.formatFloat(this.y)

              body += '<p style="margin: 0;"><b style="color: ' + this.series.color + '">' + this.series.name + ':</b> '
              body += value + ' <span style="color: #6b6565">' + units + '</span></p>'
            })

            return '<div style="border-bottom: 1px solid rgba(255, 255, 255, 0.2); padding-bottom: 4px; margin-bottom: 4px;">' + header + '</div>' + body
          }
        },

        plotOptions: {
          series: {
            events: {
              mouseOut: function (event) {
                const chart = otherChart()
                chart.xAxis[0].removePlotLine('hover')
              }
            },
            point: {
              events: {
                mouseOver: function (event) {
                  const chart = otherChart()
                  chart.xAxis[0].removePlotLine('hover')
                  chart.xAxis[0].addPlotLine({
                    id: 'hover',
                    color: '#cccccc',
                    width: 1,
                    value: this.x
                  })
                }
              }
            }
          }
        }
      },

      yAxis: {
        gridLineColor: '#545358',
        labels: {
          format: '{value}°',
          align: 'left'
        }
      }
    })

    $(selector).next('.chart-buttons').find('.add-data-btn').each(function () {
      var button = $(this)
      var series = new PointSeries(chart, {
        name: button.text(),
        timestamps: view.timestamps,
        values: button.data('values'),
        color: button.data('color'),
        units: '°',
        axis: '°',
        visible: true,
        onShow: function (series) {
          button.addClass('active')
          button.css('box-shadow', 'inset 0px 3px 0px ' + series.color)
        },
        onHide: function (series) {
          button.removeClass('active')
          button.css('box-shadow', 'none')
        }
      })

      chart.addSeries(series)
      series.show()

      $(this).data('series', series)
    })

    chart.redraw()
  }
}

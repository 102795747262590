/* global history */

import $ from 'jquery'

$(document).on('click', '[data-behavior="sort-table"]', function (e) {
  e.preventDefault()

  const table = $(this).closest('table')
  const url = $(this).attr('href')

  table.addClass('loading')

  history.replaceState({}, '', url)

  $.get(url, function (data) {
    table.replaceWith(data)
  })
})

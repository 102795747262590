import $ from 'jquery'

import BasicSeries from './basic_series'
import Utils from 'runscribe/utils'

function zip (array1, array2) {
  var result = []
  for (var i = 0; i < array1.length; ++i) {
    result.push([array1[i], array2[i]])
  }
  return result
}

export default class Series extends BasicSeries {
  constructor (chart, options) {
    super(options)

    this.chart = chart

    this.convert = options.convert || function (v) { return v }
    this.convertAll = function (values) {
      return $.map(values, function (v) { return this.convert(v) }.bind(this))
    }.bind(this)

    this.name = options.name
    this.label = options.label
    this.color = options.color
    this.units = options.units
    this.axis = options.axis
    this.smoothed = options.smoothed

    this.seriesOptions = options.series
    this.smoothingLevel = chart.smoothingLevel

    this.formatter = options.formatter

    this.serial = options.serial
    this.foot = options.foot
    this.placement = options.placement

    if (options.average) {
      this.average = this.convert(options.average)
    }

    this.timestamps = options.timestamps
    this.values = options.values
    this.distances = options.distances

    this.data = zip(this.timestamps, this.convertAll(this.values))

    this.initSeries()
    this.updateStats()
  }

  initSeries () {
    var highcharts = this.chart.highcharts

    this.pointSeries = highcharts.addSeries($.extend({}, {
      id: this.name,
      color: this.color,
      data: this.data,
      name: this.label,
      type: 'scatter',
      enableMouseTracking: false,
      zIndex: 2,
      yAxis: this.yAxisIndex,
      states: { hover: { enabled: false } },
      visible: this.visible,
      marker: {
        enabled: undefined,
        radius: 1,
        enabledThreshold: 8
      }
    }), false)

    this.series = highcharts.addSeries($.extend({}, this.seriesOptions, {
      color: this.color,
      data: this.data,
      name: this.label,
      type: 'sma',
      params: {
        period: this.periodForLevel(this.smoothingLevel)
      },
      marker: {
        enabled: false
      },
      gapSize: this.chart.gapThreshold,
      gapUnit: 'value',
      linkedTo: this.name,
      tooltip: { valueSuffix: this.units, formatter: this.formatter },
      zIndex: 2,
      yAxis: this.yAxisIndex,
      states: { hover: { enabled: false } },
      visible: this.visible,
      dataGrouping: this.dataGroupingSettingsForLevel(this.smoothingLevel)
    }), false)

    if (this.average) {
      this.averageSeries = highcharts.addSeries({
        color: this.color,
        dashStyle: 'LongDash',
        lineWidth: 0.5,
        data: [[highcharts.xAxis[0].min, this.average], [highcharts.xAxis[0].max, this.average]],
        name: `${this.label} (average)`,
        enableMouseTracking: false,
        zIndex: 1,
        yAxis: this.yAxisIndex,
        visible: this.visible
      }, false)
    }
  }

  periodForLevel (level) {
    if (level === 'high') {
      return 4
    } else if (level === 'low') {
      return 2
    } else {
      return 3
    }
  }

  dataGroupingSettingsForLevel (level) {
    if (level === 'high') {
      return {
        groupPixelWidth: 10,
        smoothed: this.smoothed,
        units: [
          ['second', [10, 15, 30]],
          ['minute', [1, 2, 5, 10, 15, 30]]
        ]
      }
    } else if (level === 'low') {
      return {
        groupPixelWidth: 2,
        smoothed: this.smoothed,
        units: [
          ['second', [1, 2, 5, 10, 15, 30]],
          ['minute', [1, 2, 5, 10, 15, 30]]
        ]
      }
    } else {
      return {
        groupPixelWidth: 5,
        smoothed: this.smoothed,
        units: [
          ['second', [2, 5, 10, 15, 30]],
          ['minute', [1, 2, 5, 10, 15, 30]]
        ]
      }
    }
  }

  setSmoothing (level) {
    this.smoothingLevel = level

    this.series.update({
      params: {
        period: this.periodForLevel(level)
      },
      dataGrouping: this.dataGroupingSettingsForLevel(level)
    })
  }

  timestampRange (from, to) {
    let fromIndex = 0
    let toIndex = this.timestamps.length - 1

    if (from && to) {
      while (fromIndex < this.timestamps.length - 1 && this.timestamps[fromIndex] <= from) {
        ++fromIndex
      }

      while (toIndex > 0 && this.timestamps[toIndex] >= to) {
        --toIndex
      }
    }

    return [fromIndex, toIndex]
  };

  afterSetExtremes () {
    super.afterSetExtremes()
    this.updateStats()
  }

  updateAverageSeriesLine () {
    var highcharts = this.chart.highcharts
    this.averageSeries.setData([[highcharts.xAxis[0].min, this.average], [highcharts.xAxis[0].max, this.average]])
  }

  updateStats () {
    if (!this.series) return

    var axis = this.series.xAxis
    var range = this.timestampRange(axis.min, axis.max)
    var values = $.grep(this.values.slice(range[0], range[1]), function (n) { return n !== null && n !== undefined })

    var payload = {
      min: this.convert(Math.min.apply(null, values)),
      max: this.convert(Math.max.apply(null, values)),
      avg: this.convert(Utils.average(values)),
      values: this.convertAll(values),
      range: { min: axis.min, max: axis.max }
    }

    if (this.distances) {
      payload.first = [this.timestamps[range[0]], this.distances[range[0]]]
      payload.last = [this.timestamps[range[1]], this.distances[range[1]]]
    }

    this.onUpdate(this, payload)
  }
}

import $ from 'jquery'
import Cookies from 'js-cookie'

import Chart from './chart'

function rejectFirstAndLast (array) {
  return array.splice(1, array.length - 2)
}

export default class RunChart extends Chart {
  constructor (id, options) {
    super(id, options)

    this.laps = options.laps

    this.markers = options.markers
    this.markerMode = options.markerMode

    this.nodata = options.nodata

    this.mode = 'run'
    this.metrics = {}
    this.series = {
      run: {},
      splits: {
        km: {}, miles: {}
      },
      laps: {},
      symmetry: {},
      leftright: {}
    }

    this.activeMetrics = []

    this.multiMode = options.multiMode || Cookies.get('multiMode') || 'single'

    this.updatePlotLines()

    const chart = this

    $.each(this.nodata, function () {
      const from = this[0]
      const to = this[1]

      chart.addPlotBand('nodata', 'rgba(0, 0, 0, 0.15)', from, to, 4)
    })
  }

  eachSeries (callback) {
    $.each(this.series, function (mode, metrics) {
      if (mode === 'splits') {
        $.each(metrics, function (markerMode, markerSeries) {
          $.each(markerSeries, function (metric, allSeries) {
            $.each(allSeries, function (i, series) {
              callback(series)
            })
          })
        })
      } else {
        $.each(metrics, function (metric, allSeries) {
          $.each(allSeries, function (i, series) {
            callback(series)
          })
        })
      }
    })
  }

  setMode (newMode) {
    // Hide active metrics for old mode
    this.hideActiveMetrics()

    this.mode = newMode

    // Show active metrics for new mode
    this.showActiveMetrics()

    if (this.mode === 'splits') {
      this.highcharts.update({ chart: { zoomType: '', panning: false } })
      this.highcharts.zoom()
    } else {
      this.highcharts.update({ chart: { zoomType: 'x', panning: true } })
    }

    this.afterSetExtremes()
  }

  setMultiMode (mode) {
    this.multiMode = mode
  }

  setMarkerMode (mode) {
    // Hide active metrics for old mode
    if (this.mode === 'splits') this.hideActiveMetrics()

    this.markerMode = mode
    this.updatePlotLines()

    // Show active metrics for new mode
    if (this.mode === 'splits') this.showActiveMetrics()
  }

  addMetric (metric) {
    this.metrics[metric.name] = metric

    // Preload run series
    this.getSeries(metric.name, 'run')
  }

  getSeries (name, mode) {
    const metric = this.metrics[name]

    let series

    if (mode === 'splits') {
      series = this.series.splits[this.markerMode][metric.name]

      if (series === undefined) {
        series = this.series.splits[this.markerMode][metric.name] = metric.splitsSeries(this)
      }
    } else {
      series = this.series[mode][name]

      if (series === undefined) {
        if (mode === 'run') {
          series = this.series.run[metric.name] = metric.runSeries(this)
        } else if (mode === 'laps') {
          series = this.series.laps[metric.name] = metric.lapsSeries(this)
        } else if (mode === 'symmetry') {
          series = this.series.symmetry[metric.name] = metric.symmetrySeries(this)
        } else if (mode === 'leftright') {
          series = this.series.leftright[metric.name] = metric.leftrightSeries(this)
        }
      }
    }

    return series
  }

  eachPaceSeries (callback) {
    var series = this.getSeries('stride_pace', 'run')
    $.each(series, function (i, series) { callback(series) })
  }

  togglePace () {
    this.eachPaceSeries(function (series) { series.toggle() })
  }

  showPace () {
    this.eachPaceSeries(function (series) { series.show() })
  }

  hidePace () {
    this.eachPaceSeries(function (series) { series.hide() })
  }

  hideActiveMetrics () {
    this.activeMetrics.forEach((metric) => this.hideMetric(metric))
  }

  showActiveMetrics () {
    this.activeMetrics.forEach((metric) => this.showMetric(metric))
  }

  clearActiveMetrics () {
    this.hideActiveMetrics()
    this.activeMetrics = []
  }

  showMetric (metric) {
    const series = this.getSeries(metric.name, this.mode)

    if (series && series.length) {
      $.each(series, function (i, series) {
        series.show()
        series.showAxis()
      })
      this.hideLoading()
    } else {
      this.showLoading('View is unavailable for the selected metric.')
    }
  }

  hideMetric (metric) {
    const series = this.getSeries(metric.name, this.mode)

    $.each(series, function (i, series) {
      series.hide()
      series.hideAxis()
    })
  }

  selectMetric (metric) {
    if (this.multiMode === 'single') {
      this.clearActiveMetrics()
    }

    this.activeMetrics.push(metric)
    this.showMetric(metric)
  }

  deselectMetric (metric) {
    this.hideMetric(metric)
    this.activeMetrics.splice(this.activeMetrics.indexOf(metric), 1)
  }

  get activeMarkers () {
    return this.markers[this.markerMode] || {}
  }

  get markerPlotLines () {
    const timestamps = rejectFirstAndLast(Object.keys(this.activeMarkers || {}))
    return timestamps.map((timestamp) => this.createPlotLine(timestamp, { color: '#2d2d2d' }))
  }

  get plotLines () {
    return this.markerPlotLines.concat(this.lapPlotLines)
  }

  get lapPlotLines () {
    const timestamps = rejectFirstAndLast(Object.keys(this.laps || {}))
    return timestamps.map((timestamp) => this.createPlotLine(timestamp, { color: '#eeeeee', dashStyle: 'Dot' }))
  }

  updatePlotLines () {
    this.highcharts.xAxis[0].update({
      plotLines: this.plotLines.filter(Boolean)
    })
  }
}

import BasicSeries from './basic_series'
import BoxPlotSeries from './box_plot_series'
import CalibrationChart from './calibration_chart'
import Chart from './chart'
import NavigatorChart from './navigator_chart'
import PointSeries from './point_series'
import RunChart from './run_chart'
import Series from './series'
import SplitChart from './split_chart'

import Highstock from './highstock'

import './symbols'
import './trend_indicator'

window.Highstock = Highstock

export default {
  BasicSeries,
  BoxPlotSeries,
  CalibrationChart,
  Chart,
  NavigatorChart,
  PointSeries,
  RunChart,
  Series,
  SplitChart
}

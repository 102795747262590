import $ from 'jquery'

import Counter from 'runscribe/ui/counter'
import Tooltips from 'runscribe/ui/tooltips'

export default class RunList {
  constructor () {
    $('[data-behavior="run-index-tags"]').each(function () {
      const list = $(this)
      const url = list.data('url')
      const target = $(list.data('target'))

      list.on('change', 'input:checkbox', function () {
        const tags = list.find('input:checkbox:checked').map((i, input) => $(input).val()).toArray()

        target.addClass('loading')

        $.get(url, { tags: tags.join(',') }, function (data) {
          target.html(data)

          target.removeClass('loading')

          Counter.countAll(target.find('[data-count]'))
          Tooltips.init()
        })
      })
    })
  }
}

import Series from './series'

export default class PointSeries extends Series {
  initSeries () {
    const highcharts = this.chart.highcharts

    this.series = highcharts.addSeries({
      name: this.name,
      color: this.color,
      data: this.data,
      tooltip: { valueSuffix: this.units },
      zIndex: 2,
      yAxis: this.yAxisIndex,
      states: { hover: { enabled: false } },
      visible: this.visible,
      type: 'line',
      lineWidth: 0,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 1.5
      },
      dataGrouping: {
        groupPixelWidth: 10,
        units: [
          ['second', [1, 5, 10, 15, 30]],
          ['minute', [1, 2, 5, 10, 15, 30]]
        ]
      }
    }, false)
  }

  showAxis () {}
  hideAxis () {}
}
